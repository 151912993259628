import React from "react";

import Layout from "../../components/layout";
import Articles from "../../components/Articles";
import HeroSection from "../../components/HeroSection";

import { graphql } from "gatsby";

import * as styles from "./styles.module.scss";

export default function BlogPosts(props) {
  const posts = props.data.allPrismicBlog.edges.map(({ node }) => ({
    cover: {
      url: node.data.cover.url,
      alt: node.data.cover.alt,
    },
    title: node.data.title.text,
    introduction: node.data.introduction.text,
    slug: node.uid,
    createdAt: node.first_publication_date,
  }));
  if (!props.data) return null;

  return (
    <Layout>
      <HeroSection
        upperHeadline="LEARNING CENTER"
        title={`<h1>DefensePoint <strong>blog</strong> page</h1>`}
      />
      <div className={styles.articlesWrapper}>
        <Articles articles={posts} />
      </div>
    </Layout>
  );
}

export const blog = graphql`
  query blogPosts {
    allPrismicBlog {
      edges {
        node {
          uid
          first_publication_date(formatString: "MM/DD/YYYY")
          data {
            title {
              text
            }
            introduction {
              text
            }
            cover {
              alt
              url
            }
          }
        }
      }
    }
  }
`;
