import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import icon from "../../images/favicon.ico";
export default function Layout(props) {
  return (
    <>
      <Helmet>
        <title>DefensePoint</title>
        <link rel='icon' href={icon} />
        <html lang='en' />
        <meta http-equiv="Cache-Control" content="no-cache" />
        <meta http-equiv="Pragma" content="no-cache" />
      </Helmet>
      <div>
        <Header type={props.type} />
        {props.children}
        <Footer />
      </div>
    </>
  );
}
